import React from "react";
import "./spinner.css";

// Spinner from https://loading.io/css/

interface SpinnerProps {
  className?: string;
  size?: number;
  message?: string;
  variant?: string;
}

function Spinner({ className, size, message, variant }: SpinnerProps) {
  const cl = className ? `lds-ring ${className}` : "lds-ring";
  const ringHeight = size ?? 80;
  const ringWidth = ringHeight;
  const outerDivStyle = { height: ringHeight, width: ringWidth };
  const innerDivBorderWidth = ringHeight / 10;
  const borderColor = variant
    ? "#99c0ea transparent transparent transparent"
    : "#0062cc transparent transparent transparent";
  const innerDivStyle = {
    height: ringHeight - innerDivBorderWidth * 2,
    width: ringWidth - innerDivBorderWidth * 2,
    margin: innerDivBorderWidth,
    borderWidth: innerDivBorderWidth,
    borderColor
  };
  return (
    <div className="d-inline-flex align-middle align-items-center">
      <div className={cl} role="status" style={outerDivStyle}>
        <div style={innerDivStyle}></div>
        <div style={innerDivStyle}></div>
        <div style={innerDivStyle}></div>
        <div style={innerDivStyle}></div>
        <span className="visually-hidden">Loading...</span>
      </div>
      <div className="ms-1">{message}</div>
    </div>
  );
}

interface CenteredSpinnerProps {
  /** A message to show while loading */
  message?: string;
}

const SHOW_MESSAGE_DELAY = 2000;

function CenteredSpinner({ message }: CenteredSpinnerProps) {
  const [showMessage, setShowMessage] = React.useState(false);
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowMessage(true);
    }, SHOW_MESSAGE_DELAY);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [message, setShowMessage]);
  const msg = message ? `${message}; this can take a few seconds...` : "";
  return (
    <div className="h-100 w-100 d-flex flex-md-column align-items-center justify-content-center">
      <Spinner />
      <div>{showMessage ? msg : " "}</div>
    </div>
  );
}

export default Spinner;

export { CenteredSpinner };

export type { SpinnerProps, CenteredSpinnerProps };
