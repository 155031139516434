import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

type OopsProps = {
  onClearError: React.MouseEventHandler<HTMLButtonElement>;
};

function Oops({ onClearError }: OopsProps) {
  return (
    <>
      <h1>Oops!</h1>
      <p className="fs-3">Something went wrong. Sorry about that!</p>
      <div>
        If you want,{" "}
        <Button onClick={onClearError} size="sm">
          Retry
        </Button>{" "}
        your action and maybe the problem will go away.
      </div>
      <div className="pt-4">
        Otherwise, you should{" "}
        <Link to="/">
          <Button onClick={onClearError}>Return to homepage</Button>
        </Link>
      </div>
    </>
  );
}

type ErrorBoundaryProps = unknown;

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true };
  }

  //potential logging:
  //   componentDidCatch(error, errorInfo) {
  //   }

  render() {
    if (this.state.hasError)
      return <Oops onClearError={() => this.setState({ hasError: false })} />;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
      this.props as {
        children?: JSX.Element | JSX.Element[] | string | string[];
        [key: string]: unknown;
      }
    ).children;
  }
}

export default ErrorBoundary;
